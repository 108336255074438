

import React from 'react';

function Categories() {

  const handleClick = () => {
    const categoryValue = document.getElementById('txtCategory').value;
    // Making AJAX call to ajax.php
    fetch('https://www.riddles-and-more.com/ajax.php', {
      method: 'POST',
      body: JSON.stringify({ action: "addCategory", category: categoryValue }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      // Alerting the message returned from the server
      alert(data.message);
    })
    .catch(error => {
      console.error('Error:', error);
      // Handling error
      alert('An error occurred while processing your request.');
    });
  };

  return (
    <div>
      <input type="text" id="txtCategory" placeholder="Category Name" />
      <button id="btnAddCategory" onClick={handleClick}>Add Category</button>
    </div>
  );
}

export default Categories;
