import styles from '../App.module.scss';
import DataSender from './DataSender';
import Categories from './admin/Categories';

function Jokes() {
  return (
    <div>
<span class="trenton">Joke page</span>
<DataSender />
<Categories />
    </div>
  );
}

export default Jokes;